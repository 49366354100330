import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Dictionary} from 'highcharts';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddEditDialogModel} from '../../metaview/addedit/addedit.component';
import {TableConfig} from '../../metaview/table/table.component';

export interface Field {
  name: string;
  label: string;
  fieldType: FieldType;
  values?: Array<string>;
  pattern?: string;
  required?: boolean;
  readOnly?: boolean;
}

export enum FieldType {
  F_STRING,
  F_NUMBER,
  F_DATE,
  F_EMAIL,
  F_DROPDOWN,
  F_PHONE,
  F_SSN
}

@Component({
  selector: 'app-member-addedit',
  templateUrl: './member-addedit.component.html',
  styleUrls: ['./member-addedit.component.scss']
})
export class MemberAddeditComponent implements OnInit {

  protected form!: UntypedFormGroup;
  protected fieldType = FieldType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MemberAddeditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MemberAddeditDialogModel,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    const controls: Dictionary<any> = {};
    this.data.fields.forEach(field => {
      controls[field.name] = new UntypedFormControl(this.data.data ? this.data.data[field.name] : null, field.required ? [Validators.required] : []);
    });
    this.form = this.formBuilder.group(controls);
  }

  okClicked(): void {
    this.dialogRef.close(this.form.value);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }

}

export class MemberAddeditDialogModel {
  constructor(public data: Dictionary<any> | null, public fields: Array<Field>, public title: string) {
  }
}


