import { Injectable } from '@angular/core';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {Dictionary} from 'highcharts';
import {unflatten} from 'flat';
import {SnackBarComponent} from '../../components/snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Field} from '../../components/metaview/addedit/addedit.component';
import {Plan} from '../stripe/stripe.service';
import {Router} from '@angular/router';
import {UiAlertService} from '../ui-alert/ui-alert.service';
import {TeamsService} from '../teams/teams.service';
import {SessionService} from '../session/session.service';
import {SpinnerService} from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
      private snackbar: MatSnackBar,
      private router: Router,
      private uiAlertService: UiAlertService,
      private teamsService: TeamsService,
      private sessionService: SessionService,
      private spinnerService: SpinnerService
  ) { }

  getFormFields(form: UntypedFormGroup, formFields: Array<Field>): { [key: string]: any } {
    const fields: Dictionary<any> = {};
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value !== null && !key.startsWith('_')) {
        fields[key] = formFields.find(formField => formField.name === key)?.fieldType === 'Number' ? parseFloat(value.value) : value.value;
      }
    }
    return unflatten(fields);
  }

  getFormErrors(formGroup: FormGroup, doneFunc: (message: string) => {}): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    setTimeout(() => {
      const errors: any = {};
      Object.keys(formGroup.controls).forEach(key => {
        const controlErrors = formGroup.get(key)?.errors;
        if (controlErrors) {
          errors[key] = controlErrors;
        }
      });
      let errorMessage = '';
      console.log('ERRORS', errors)
      Object.keys(errors).forEach(key => {
        if (errorMessage) {
          errorMessage += ', ';
        }
        errorMessage += `${errors[key].required ? 'Missing' : 'Invalid'} ${key}`;
      });
      doneFunc(errorMessage);
    }, 0);
  }



  getSimpleFormFields(form: UntypedFormGroup): { [key: string]: any } {
    const formValues = {};
    for (const controlName in form.value) {
      if (form.value.hasOwnProperty(controlName)) {
        // @ts-ignore
        formValues[controlName] = form.value[controlName];
      }
    }
    return formValues;
  }

  showError(error: Dictionary<any>): void {
    this.snackbar.openFromComponent(SnackBarComponent, {data: error.error.errors ? `${error.error.errors.map((errorInfo: any) => `${errorInfo.path}: ${errorInfo.message}`).join(', ')}`
     : error.error.message});
  }

  async addWellspace(): Promise<string | Dictionary<any>> {
    try {
      const wellspaceDetails = await this.uiAlertService.presentFieldInput('Add a New Wellspace', 'Wellspace Name', '');
      if (wellspaceDetails) {
        this.spinnerService.show('Creating a new wellspace...');
        const userId = this.sessionService.getUserId();
        const teamDetails = await this.teamsService.createTeam(userId, wellspaceDetails);
        this.teamsService.setWellspace(teamDetails);
        this.spinnerService.hide();
        this.snackbar.openFromComponent(SnackBarComponent, {data: 'New wellspace created'});
        await this.router.navigate(['managewellspace/overview/current']);
        return wellspaceDetails;
      }
    } catch (error: any) {
      if (error.status === 422) {
        console.log(error.error);
        this.snackbar.openFromComponent(
            SnackBarComponent,
            {
              data: {
                buttons: [
                    {
                      label: 'Upgrade',
                      link: 'managewellspace/plan/current',
                    },
                ],
                message: `You may only have one wellspace on the free plan. Please upgrade in order to add more teams.`,
              },
            });
        this.spinnerService.hide();
        return Promise.resolve([]);
      }
      console.log('An error occurred while trying to create a new wellspace.', error);
      this.snackbar.openFromComponent(SnackBarComponent, {data: 'An error occurred while trying to create a new wellspace, please try again.'});
      this.spinnerService.hide();
    }
    return '';
  }

  getUserName(user: any): string {
    let name = `${user.firstName || ''} ${user.lastName || ''}`;
    if (user.email) {
      name += ` (${user.email})`;
    }
    return name;
  }

  capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  vendorProductSlugToDisplay(slug: string): string {
    return slug?.split('-').map(word => this.capitalize(word)).join(' ');
  }

}
