import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsersService} from '../../../services/users/users.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {Dictionary} from 'highcharts/highcharts.src';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AppSettings} from '../../../app.settings';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {FormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {Session} from 'protractor';
import {SessionService} from '../../../services/session/session.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {UtilsService} from '../../../services/utils/utils.service';

@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.scss']
})
export class ManageusersComponent implements OnInit, AfterViewInit {

  @ViewChild('search') search: ElementRef | undefined;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public loading = false;
  private pageNumber = 0;
  public items = new MatTableDataSource<any>();
  public columnsToDisplay = ['createdAt', 'partnerImage', 'firstName', 'lastName', 'email', 'phoneNumber', 'address1', 'city', 'state', 'postalCode', 'signedUpBy', 'action'];
  public iFrameSource: SafeUrl | undefined;
  public iFrameTitle = '';
  public partners = Array<Dictionary<any>>();
  public form;
  private partner = '';
  private atEnd = false;
  private wallitBrand = {partnerId: ''};
  private appWindow: any;
  public isSuperAdmin = false;

  constructor(
      private usersService: UsersService,
      private domSanitizer: DomSanitizer,
      private uiAlertService: UiAlertService,
      private utilsService: UtilsService,
      private formBuilder: FormBuilder,
      private snackBar: MatSnackBar,
      private sessionService: SessionService,
      private spinnerService: SpinnerService,
  ) {
    this.form = this.formBuilder.group({
      partner: new UntypedFormControl(0, [Validators.required]),
    });
  }

  ngOnInit(): void {
    let firstTime = true;
    this.usersService.meSubscribe((response: any) => {
      if (response && firstTime) {
        firstTime = false;
        this.usersService.getBrands().then(brands => {
          this.wallitBrand.partnerId = brands.find((brand: { name: string; }) => brand.name === 'Wallit').partnerId;
        });
        this.usersService.getVisibleBrands().then(partners => {
          this.partners = partners;
          this.loadMoreItems();
        });
        this.form.get('partner')?.valueChanges.subscribe(value => {
          this.partner = value ? this.partners[value - 1].partnerId : '';
          this.loadMoreItems(true);
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.items.sort = this.sort;
    fromEvent(this.search?.nativeElement, 'keyup')
        .pipe(
            filter(Boolean),
            debounceTime(300),
            distinctUntilChanged(),
            tap((text) => {
              this.loadMoreItems(true);
            })
        )
        .subscribe();
  }

  onScroll(event: any): void {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const threshold = 150;

    if (scrollTop + offsetHeight >= scrollHeight - threshold) {
      this.loadMoreItems();
    }
  }

  loadMoreItems(clear = false): void {
    if (clear) {
      this.atEnd = false;
      this.pageNumber = 0;
      this.items.data = [];
    }
    if (this.loading || this.atEnd) {
      return;
    }
    this.loading = true;
    this.pageNumber++;
    let allPartners = this.partners;
    if (this.sessionService.isSuperAdmin()) {
      this.isSuperAdmin = true;
      allPartners = [...allPartners, this.wallitBrand];
    }
    this.spinnerService.show();
    this.usersService.getUsers(this.search?.nativeElement.value, false, this.partner ? this.partner : allPartners.map(partner => partner.partnerId).join(','), this.pageNumber, 20).then(users => {
      this.spinnerService.hide();
      this.loading = false;
      if (users.length === 0) {
        this.atEnd = true;
      }
      this.items.data = this.items.data.concat(users);
      this.items.data.forEach(item => {
        const partner = this.partners.find(thisPartner => thisPartner.partnerId === item.partnerId);
        item.partnerImage = this.domSanitizer.bypassSecurityTrustUrl(partner?.logoUrl);
        item.partnerName = partner?.name;
        item.signedUpBy = item.signupUserId ? item.signupUserId === item.id ? 'Self' : 'Agent' : 'Unknown';
      });
    });
  }

  userLogin(user: any): void {
    this.usersService.loginUserV3(user.id, AppSettings.SUPPORT_DEV_MODE, (url) => {
      this.iFrameSource = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      this.iFrameTitle = `${user.firstName || ''} ${user.lastName || ''}`;
      if (user.email) {
        this.iFrameTitle += ` (${user.email})`;
      }
      this.appWindow = window.open(url, '_blank', 'width=400,height=800,left=100,top=100,menubar=false,location=false,toolbar=false,status=false');
    });
  }

  closeIframe(): void {
    if (this.appWindow) {
      this.appWindow.close();
      this.appWindow = undefined;
    }
    this.iFrameSource = '';
  }

  addUser(): void {
    this.uiAlertService.presentAddUser().then(result => {
      if (result) {
        this.loadMoreItems(true);
      }
    });
  }

  private refresh(): void {
    this.items.data = [...this.items.data];
  }

  disableUser(user: any): void {
    this.usersService.disableUserAccount(user.id).then(_ => {
      user.isActivatedUser = false;
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'User account has been disabled'});
      this.refresh();
    });
  }

  enableUser(user: any): void {
    this.usersService.enableUserAccount(user.id).then(_ => {
      user.isActivatedUser = true;
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'User account has been enabled'});
      this.refresh();
    });
  }

  setPassword(user: any): void {
    this.uiAlertService.presentFieldInput('Set User Password', 'Password').then(value => {
      if (value) {
        this.usersService.setPassword(user.id, value).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Password successfully changed'});
        });
      }
    });
  }

  loginHistory(user: any): void {
    this.uiAlertService.showUserLogins(user);
  }

  signedUpByClicked(item: any): void {
    if (item.signupUserId && item.id !== item.signupUserId) {
      this.usersService.getUserById(item.signupUserId).then((user: any) => {
        this.uiAlertService.presentMessage(`Signed up by ${this.utilsService.getUserName(user)}`);
      });
    }
  }

  playHelpMovie(): void {
    this.uiAlertService.presentMovie('Managing Customers' , UiAlertService.content.MANAGING_CUSTOMERS);
  }

  userEdit(user: any): void {
    this.uiAlertService.presentAddUser(user).then(result => {
      if (result) {
        Object.assign(user, result);
      }
      this.loadMoreItems(true);
    });
  }

}
