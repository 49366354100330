<div class="actions">
    <button [disabled]="!companyId" mat-raised-button color="primary" (click)="addItem()">
        <mat-icon>add</mat-icon>
        Add new member
    </button>
    <button [disabled]="!companyId" (click)="export()" mat-raised-button color="accent">
        <mat-icon>file_download</mat-icon>
        Export
    </button>
    <button [disabled]="!companyId" (click)="uploadErrors = undefined; fileUpload.click()" mat-raised-button color="accent">
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
        <mat-icon>file_upload</mat-icon>
        Import
    </button>
    <button (click)="downloadTemplate()" mat-raised-button color="accent">
        <mat-icon>file_copy</mat-icon>
        <a class="downloadbutton" download="filename" target="_blank" [href]="table?.templateUrl" (click)="downloadTemplate()">
            Download Template
        </a>
    </button>
</div>
<div class="errors" *ngIf="uploadErrors">
    <span>{{uploadErrors.errorMessage}}</span><br>
    <span *ngFor="let error of uploadErrors.errorDetails">{{error.type}} '{{error.info}}', Line {{error.lineNumber}}</span><br>
</div>
<table *ngIf="table" class="table" mat-table matSort matTableExporter [dataSource]="items">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox #ref (change)="$event ? masterToggle(ref) : null"
                          [checked]="selection.hasValue() && isAllSelected() && isSomeSelected()"
                          [indeterminate]="selection.hasValue() && !isSomeSelected && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" >
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null;isSomeSelected()"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container *ngFor="let label of columnLabels; index as i" [matColumnDef]="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">{{label}}</th>
        <td class="cell" mat-cell *matCellDef="let item">{{columnData[i](item)}}</td>
    </ng-container>
    <ng-container matColumnDef="actionmenu" stickyEnd>
        <th mat-header-cell matTooltip="Admin Team Actions" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item"><button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: item}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="allColumnLabels"></tr>
    <tr class="listitem" mat-row *matRowDef="let rows; columns: allColumnLabels"></tr>
    <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button *ngFor="let menuItem of table.menuItems" mat-menu-item (click)="menuItem.click(item)">{{menuItem.name}}</button>
        </ng-template>
    </mat-menu>
</table>
