<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{data.data ? 'Edit' : 'Add New'}} {{data.title}}
</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <ng-container *ngFor="let field of data.fields">
            <mat-form-field appearance="fill" floatLabel="always" *ngIf="!field.invisible?.(form)">
                <mat-label>{{field.label}}</mat-label>
                <input *ngIf="field.fieldType === fieldType.F_STRING" matInput placeholder="" [formControlName]="field.name">
                <input *ngIf="field.fieldType === fieldType.F_NUMBER" type="number" matInput placeholder="" [formControlName]="field.name">
                <input *ngIf="field.fieldType === fieldType.F_SSN" type="password" pattern="^\d{3}-?\d{2}-?\d{4}$" matInput placeholder="" [formControlName]="field.name">
                <input *ngIf="field.fieldType === fieldType.F_PHONE" type="tel" matInput placeholder="" [formControlName]="field.name">
                <input *ngIf="field.fieldType === fieldType.F_EMAIL" type="email" matInput placeholder="" [formControlName]="field.name">
                <input *ngIf="field.fieldType === fieldType.F_PASTDATE || field.fieldType === fieldType.F_FUTUREDATE" [matDatepicker]="picker"
                       matInput placeholder="" [formControlName]="field.name" [min]="field.minDate" [max]="field.maxDate">
                <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="field.fieldType === fieldType.F_PASTDATE || field.fieldType === fieldType.F_FUTUREDATE"></mat-datepicker-toggle>
                <mat-select *ngIf="field.fieldType === fieldType.F_DROPDOWN" [formControlName]="field.name">
                    <mat-option *ngFor="let value of field.values" [value]="value.split(':')[1] || value">{{value.split(':')[0]}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <mat-datepicker #picker></mat-datepicker>
    </form>
    <span class="required" >* Required Field</span>
    <app-inlinemessage *ngIf="errorMessage" level="error" [message]="errorMessage"></app-inlinemessage>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="okClicked()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
