import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {Field, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {FieldInputDialogComponent, FieldInputDialogModel} from '../../modal/fieldinputdialog/fieldinputdialog.component';
import {MatDialog} from '@angular/material/dialog';

export const State = {
  add: ['Add', 'add', 'A', 'a'],
  update: ['Update', 'update', 'U', 'u'],
  remove: ['Remove', 'remove', 'R', 'r']
};

export interface Table {
  title: string;
  addEditFields: Array<Field>;
  columnsToDisplay: Array<
    {
      data: (row: { [x: string]: any; }) => any ,
      label: string,
    }
  >;
  menuItems: Array<
    {
      name: string,
      click: (arg: any) => void;
    }
  >;
  templateUrl: string;
}

@Component({
  selector: 'app-member-manager',
  templateUrl: './member-manager.component.html',
  styleUrls: ['./member-manager.component.scss']
})
export class MemberManagerComponent implements OnInit, OnChanges {

  public selection = new SelectionModel<any>(true, []);
  public items = [];
  public columnLabels: any;
  public allColumnLabels: any;
  public columnData: any;
  public uploadErrors: {count: number, errorMessage: string, errorDetails: Array<{lineNumber: number, type: string, info: string}>} | undefined;

  @Input() table: Table | undefined;

  @Input() companyId = '';
  @Input() state = '';

  constructor(
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar,
      private spinnerService: SpinnerService,
      private uiAlertService: UiAlertService,
      private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.columnData = this.table?.columnsToDisplay.map(column => column.data);
    this.columnLabels = this.table?.columnsToDisplay.map(column => column.label);
    this.allColumnLabels = [...this.columnLabels, 'actionmenu'];
    this.table?.menuItems.push({name: 'Edit', click: this.itemEdit.bind(this)});
    this.table?.menuItems.push({name: 'Remove', click: this.itemRemove.bind(this)});
    this.loadItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadItems();
  }

  private loadItems(): void {
    if (this.companyId) {
      this.spinnerService.show();
      this.usersInsuranceService.getInsuranceState(this.companyId, this.state).then(result => {
        this.spinnerService.hide();
        this.items = result.filter((item: { state: string; }) => item.state === this.state).map((item: { metadata: { upload: any; }; }) => {
          const newItem = Object.assign({}, item, item.metadata.upload);
          delete newItem.metadata;
          return newItem;
        });
      });
    } else {
      this.items = [];
    }
  }

  private itemEdit(item: any): void {

  }

  private itemRemove(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this item?').then(ok => {
      if (ok) {
        this.usersInsuranceService.deleteInsuranceState(item.id).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Item removed'});
          this.loadItems();
        }).catch(error => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Error removing item: ${error}`});
        });
      }
    });
  }

  export(): void {

  }

  addItem(): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(null, this.table?.addEditFields || [], this.table?.title || '')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.usersInsuranceService.createInsuranceState(this.companyId, '', dialogResult.Email, dialogResult, this.state).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Item added`});
          this.loadItems();
        });
      }
    });
  }

  downloadTemplate(): void {
    this.snackBar.openFromComponent(SnackBarComponent, {data: 'Template download completed' });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.length;
    return numSelected === numRows;
  }

  isSomeSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(ref: { checked: boolean; }): void {
    if (this.isSomeSelected()) {
      this.selection.clear();
      ref.checked = false;
    } else {
      ref.checked = true;
      this.items.forEach((row: any) => this.selection.select(row));
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      // this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        event.target.value = '';
        this.spinnerService.show('Uploading members');
        this.usersInsuranceService.uploadInsuranceState(this.companyId, this.state, reader.result).then(result => {
          this.spinnerService.hide();
          this.loadItems();
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Upload completed successfully. ${result.count} records processed.` });
        }).catch(error => {
          this.spinnerService.hide();
          this.uploadErrors = error.error;
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }

}
