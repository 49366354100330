import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsersService} from '../../../services/users/users.service';
import {from, fromEvent, of} from 'rxjs';
import {concatMap, debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {Dictionary} from 'highcharts/highcharts.src';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {
  EditCompanyManagersDialogComponent,
  EditCompanyManagersDialogModel
} from '../../modal/editcompanymanagersdialog/editcompanymanagersdialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {SessionService} from '../../../services/session/session.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {TeamCompanyUsersService} from '../../../services/teamcompanyusers/teamcompanyusers.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';

@Component({
  selector: 'app-managesupportagents',
  templateUrl: './managesupportagents.component.html',
  styleUrls: ['./managesupportagents.component.scss']
})
export class ManagesupportagentsComponent implements OnInit, AfterViewInit {

  @ViewChild('search') search: ElementRef | undefined;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public loading = false;
  private pageNumber = 0;
  public items = new MatTableDataSource<any>();
  public form;
  public columnsToDisplay = ['firstName', 'lastName', 'email', 'phoneNumber', 'companyName', 'action'];
  private atEnd = false;
  public partners = Array<Dictionary<any>>();
  private partner = '';
  private wallitBrand = {partnerId: ''};

  constructor(
      private usersService: UsersService,
      private domSanitizer: DomSanitizer,
      private dialog: MatDialog,
      private formBuilder: FormBuilder,
      private sessionService: SessionService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private teamCompanyUsers: TeamCompanyUsersService,
      private spinnerService: SpinnerService
  ) {
    this.form = this.formBuilder.group({
      partner: new UntypedFormControl(0, [Validators.required]),
    });
  }

  ngOnInit(): void {
    let firstTime = true;
    this.usersService.meSubscribe((response: any) => {
      if (response && firstTime) {
        firstTime = false;
        this.usersService.getBrands().then(brands => {
          this.wallitBrand.partnerId = brands.find((brand: { name: string; }) => brand.name === 'Wallit').partnerId;
        });
        this.usersService.getVisibleBrands().then(partners => {
          this.partners = partners;
          this.loadMoreItems();
        });
        this.form.get('partner')?.valueChanges.subscribe(value => {
          this.partner = value ? this.partners[value - 1].partnerId : '';
          this.loadMoreItems(true);
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.items.sort = this.sort;
    fromEvent(this.search?.nativeElement, 'keyup')
        .pipe(
            filter(Boolean),
            debounceTime(300),
            distinctUntilChanged(),
            tap((text) => {
              this.pageNumber = 1;
              this.items.data = [];
              this.loadMoreItems(true);
            })
        )
        .subscribe();
  }

  onScroll(event: any): void {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const threshold = 150;

    if (scrollTop + offsetHeight >= scrollHeight - threshold) {
      this.loadMoreItems();
    }
  }

  private selectedPartners(): string {
    let allPartners = this.partners;
    if (this.sessionService.isSuperAdmin()) {
      allPartners = [...allPartners, this.wallitBrand];
    }
    return this.partner ? this.partner : allPartners.map(partner => partner.partnerId).join(',')
  }

  loadMoreItems(clear = false): void {
    if (clear) {
      this.atEnd = false;
      this.pageNumber = 0;
      this.items.data = [];
    }
    if (this.loading || this.atEnd) {
      return;
    }
    this.loading = true;
    this.pageNumber++;
    this.spinnerService.show();
    this.usersService.getAgents(this.search?.nativeElement.value, this.selectedPartners(), this.pageNumber, 20).then(users => {
      this.spinnerService.hide();
      if (users.length === 0) {
        this.atEnd = true;
      }
      this.loading = false;
      this.items.data = this.items.data.concat(users);
    });
  }

  addAgent(): void {
    if (!this.partner) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'You must first select a partner before adding agents'});
      return;
    }
    const currentPartner = this.partners.find(partner => partner.partnerId === this.partner);
    this.usersService.getCompanies(this.partners.find(partner => partner.partnerId === this.partner)?.partnerId).then(companies => {
      const dialogRef = this.dialog.open(EditCompanyManagersDialogComponent, {
        data: new EditCompanyManagersDialogModel(companies[0], true) // TODO: SUPPORT MULTIPLE COMPANIES PER PARTNER
      });
      dialogRef.afterClosed().subscribe((dialogResult: any) => {
        this.loadMoreItems(true);
      });
    });
  }

  removeAgent(agent: any): void {
      this.uiAlertService.presentAlertConfirm(`Do you really want to remove this agent?`).then(ok => {
        if (ok) {
          this.teamCompanyUsers.deleteCompanyUser(agent.id).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Agent removed`});
            this.loadMoreItems(true);
          });
        }
      });
  }

  addAllAgent(agent: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to add this agent to all partners?`).then(ok => {
      if (ok) {
        this.teamCompanyUsers.addCompanyUserAllPartners(agent.id).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Agent added to all partners`});
          this.loadMoreItems(true);
        });
      }
    });
  }

  removeAllAgent(agent: any): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to remove this agent from all partners?`).then(ok => {
      if (ok) {
        this.teamCompanyUsers.deleteCompanyUserAllPartners(agent.id).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Agent removed from all partners`});
          this.loadMoreItems(true);
        });
      }
    });
  }

  loginAgent(agent: any): void {
    this.sessionService.loginByUserId(agent.userId);
  }

  playHelpMovie(): void {
    this.uiAlertService.presentMovie('Managing Agents' , UiAlertService.content.MANAGING_AGENTS);
  }

  syncAgents(): void {
    this.uiAlertService.presentAlertConfirm(`Do you really want to synchronize all agents across all partners?`).then(ok => {
      if (ok) {
        const calls = [];
        for (const [index, item] of this.items.data.entries()) {
          const func = () => {
            this.spinnerService.show(`Synchronizing ${index + 1} of ${this.items.data.length}`);
            return this.teamCompanyUsers.addCompanyUserAllPartners(item.id);
          };
          calls.push(func);
        }
        from(calls).pipe(concatMap(call => call())).subscribe({
          complete: () => {
            this.spinnerService.hide();
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Agents synchronized`});
            this.loadMoreItems(true);
          }
        });
      }
    });
  }

}
