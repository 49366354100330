<div class="actions">
    <button class="exportbutton" mat-raised-button color="accent" (click)="export()"><mat-icon>file_download</mat-icon>Export to CSV</button>
    <button mat-fab (click)="addCompany()"><mat-icon>add</mat-icon></button>
</div>
<br><input class="filterinput" matInput [(ngModel)]="filter" (keyup)="applyFilter($event)" placeholder="Filter by...">
<button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
    <mat-icon>close</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="editCompany(item)">Edit Company</button>
        <button mat-menu-item (click)="setManagers(item)">Roles</button>
        <button mat-menu-item (click)="setCompanyParent(item)">Set Parent Company</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="addCompany(item)">Add New Company Here</button>
        <button mat-menu-item (click)="removeCompany(item)">Remove</button>
    </ng-template>
</mat-menu>
<mat-menu #teammenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="setTeamEmployer(item)">Set Employer</button>
        <button mat-menu-item (click)="viewTeam(item)">View</button>
    </ng-template>
</mat-menu>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <span class="tree-node-placeholder"></span>
        <div class="nodeline">
            <div class="iconline">
                <mat-icon *ngIf="node.type !== 'wallit'" [matTooltip]="getTooltip(node)">{{getIcon(node)}}</mat-icon>
                <mat-icon *ngIf="node.type === 'wallit'" svgIcon="wallit"></mat-icon>
                <img *ngIf="node.logoUrl" [src]="node.logoUrl" class="logourl" (error)="logoError($event)">
                <span>{{node.name}} {{node.type !== 'team' ? '(' + node.children.length + ')' : ''}}</span>
            </div>
            <button mat-button class="cell" [matMenuTriggerFor]="node.type !== 'team' ? menu : teammenu" [matMenuTriggerData]="{item: node}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button>
        </div>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <div class="nodeline">
                <div class="iconline">
                    <mat-icon *ngIf="node.type !== 'wallit'" [matTooltip]="getTooltip(node)">{{getIcon(node)}}</mat-icon>
                    <mat-icon *ngIf="node.type === 'wallit'" svgIcon="wallit"></mat-icon>
                    <img *ngIf="node.logoUrl" [src]="node.logoUrl" class="logourl" (error)="logoError($event)">
                    <span>{{node.name}} {{node.type !== 'team' ? '(' + node.children.length + ')' : ''}}</span>
                </div>
                <button mat-button class="cell" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: node}"><mat-icon matTooltip="Actions" class="more">more_vert</mat-icon></button>
            </div>
        </div>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
             role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree>
