<div class="topline">
    <h1>Clients</h1>
    <mat-form-field>
        <mat-label>Current Client</mat-label>
        <mat-select (selectionChange)="setClient($event)">
            <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<nav mat-tab-nav-bar #tabs>
    <a mat-tab-link *ngFor="let link of links" (click)="gotoTab(link.link)"
       [active]="activeLink == link">{{link.title}}</a>
</nav>
<app-client-census [clientId]="selectedClientId" *ngIf="activeLink?.title === 'Census'"></app-client-census>
<app-client-eligibility [clientId]="selectedClientId" *ngIf="activeLink?.title === 'Eligibility'"></app-client-eligibility>
<app-client-enrollment [clientId]="selectedClientId" *ngIf="activeLink?.title === 'Enrollment'"></app-client-enrollment>
