import {Component, Input} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType} from '../member-addedit/member-addedit.component';

@Component({
  selector: 'app-client-census',
  templateUrl: './client-census.component.html',
  styleUrls: ['./client-census.component.scss']
})
export class ClientCensusComponent {

  @Input() clientId = '';

  public table: Table = {
    title: 'Census Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 1', label: 'Address 1', fieldType: FieldType.F_STRING, required: true},
      {name: 'Address 2', label: 'Address 2', fieldType: FieldType.F_STRING},
      {name: 'City', label: 'City', fieldType: FieldType.F_STRING, required: true},
      {name: 'State', label: 'State', fieldType: FieldType.F_STRING, required: true},
      {name: 'ZIP', label: 'ZIP Code', fieldType: FieldType.F_STRING, required: true},
      {name: 'Email', label: 'Email Address', fieldType: FieldType.F_EMAIL, required: true},
      {name: 'Phone', label: 'Phone Number', fieldType: FieldType.F_PHONE, required: true},
      {name: 'Date of Birth', label: 'Date of Birth', fieldType: FieldType.F_DATE, required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
      {name: 'Smoking', label: 'Smoking', fieldType: FieldType.F_DROPDOWN, values: ['Yes', 'No'], required: true}
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.SSN,
        label: 'SSN'
      }
    ],
    menuItems: [
    ],
    templateUrl: '/assets/files/Wallit-census.xlsx'
  };

}
