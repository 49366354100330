import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Table} from '../member-manager/member-manager.component';
import {FieldType} from '../member-addedit/member-addedit.component';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelectChange} from '@angular/material/select';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';

@Component({
  selector: 'app-client-eligibility',
  templateUrl: './client-eligibility.component.html',
  styleUrls: ['./client-eligibility.component.scss']
})
export class ClientEligibilityComponent implements AfterViewInit{

  @Input() clientId = '';
  @ViewChild('search') search: ElementRef | undefined;

  public reload = false;
  public itemCount = 0;

  public table: Table = {
    title: 'Eligible Member',
    addEditFields: [
      {name: 'Given Name', label: 'First Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'Family Name', label: 'Last Name', fieldType: FieldType.F_STRING, required: true},
      {name: 'SSN', label: 'Social Security Number', fieldType: FieldType.F_SSN, required: true},
    ],
    columnsToDisplay: [
      {
        data: (row: { [x: string]: any; }) => row['Given Name'],
        label: 'First Name',
      },
      {
        data: (row: { [x: string]: any; }) => row['Family Name'],
        label: 'Last Name'
      },
      {
        data: (row: { [x: string]: any; }) => row.SSN,
        label: 'SSN'
      },
      {
        data: (row: { [x: string]: any; }) => row.state,
        label: 'State'
      }
    ],
    menuItems: [
      {name: 'Make Ineligible', disabled: this.ineligibleDisabled.bind(this), click: this.makeIneligible.bind(this)},
      {name: 'Enroll', disabled: this.enrollDisabled.bind(this), click: this.enroll.bind(this)}
    ],
    templateUrl: '/assets/files/Wallit-eligibility.xlsx',
    where: {
      limit: 50,
      offset: 0,
      sort: '',
      filter: {}
    }
  };

  constructor(
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar
  ) {
  }

  ngAfterViewInit(): void {
    fromEvent(this.search?.nativeElement, 'keyup').pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((): void => {
          Object.assign(this.table.where.filter, {search: this.search?.nativeElement.value.trim().toLowerCase()});
          this.reload = !this.reload;
    })).subscribe();
  }

  enrollDisabled(item: any): boolean {
    return item.state !== 'eligible';
  }

  ineligibleDisabled(item: any): boolean {
    return item.state !== 'eligible';
  }

  makeIneligible(item: any): Promise<boolean> {
    return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'census'}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member set to ineligible' });
      return true;
    });
  }

  enroll(item: any): Promise<boolean> {
    // TODO: ADD SELECT PRODUCT
    return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'enrolled'}).then(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {data: 'Member set to enrolled' });
      return true;
    });
  }

  setStateFilter(event: MatSelectChange): void {
    Object.assign(this.table.where.filter, {state: event.value});
    this.reload = !this.reload;
  }

  tableUpdated(count: number): void {
    this.itemCount = count;
  }

  pageEvent(event: any): void {
    this.table.where.limit = event.pageSize;
    this.table.where.offset = event.pageIndex * event.pageSize;
    this.reload = !this.reload;
  }

}
