import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  public selectedClientId = '';
  public clients: any;

  public links = [
    { title: 'Census', link: '/brokeragency/clients/client-census'},
    { title: 'Eligibility', link: '/brokeragency/clients/client-eligibility'},
    { title: 'Enrollment', link: '/brokeragency/clients/client-enrollment'}
  ];

  public activeLink: any;

  constructor(
      private teamCompaniesService: TeamCompaniesService,
      private router: Router
  ) { }

  ngOnInit(): void {
    const parsedUrl = this.router.parseUrl(this.router.url);
    const urlSegments = parsedUrl.root.children.primary.segments;
    urlSegments.length = 3;
    this.activeLink = this.links.find(link => link.link === parsedUrl.toString());
    this.teamCompaniesService.getCompanies().then((companies: any) => {
      this.clients = companies.filter((company: { type: string; }) => company.type === 'employer');
    });

  }

  gotoTab(link: string): void {
    this.router.navigate([link]);
    this.activeLink = link;
  }

  setClient(event: any): void {
    this.selectedClientId = event.value;
  }

}
