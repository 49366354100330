<div class="topline">
    <h1>Manage Support Agents</h1>
    <div class="rightfabs">
        <button mat-fab (click)="playHelpMovie()" matTooltip="Help movie"><mat-icon>movie</mat-icon></button>
        <button mat-fab (click)="syncAgents()" matTooltip="Synchronize agents across partners"><mat-icon>sync</mat-icon></button>
        <button mat-fab (click)="addAgent()" matTooltip="Add new agent"><mat-icon>add</mat-icon></button>
    </div>
</div>
<form class="filters" [formGroup]="form">
    <input class="search" #search matInput placeholder="Search agents by name, email, etc...">
    <mat-select class="partner" formControlName="partner" name="partner">
        <mat-option selected [value]="0">All Partners</mat-option>
        <mat-option *ngFor="let partner of partners; let i = index" [value]="i+1">{{partner.name}}</mat-option>
    </mat-select>
</form>
<div class="wholescreen">
    <span *ngIf="items.data.length === 0 && !loading">No agents match your search criteria.</span>
    <div *ngIf="items.data.length > 0" class="scroll-container" (scroll)="onScroll($event)">
        <table class="table" mat-table matSort [dataSource]="items">
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">First Name</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.firstName}}</td>
            </ng-container>
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Last Name</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.lastName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Email</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.email}}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Phone Number</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.phoneNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Partner</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.companyName}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td class="cell" mat-cell *matCellDef="let item">
                    <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="transactionMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
                </td>
            </ng-container>
             <mat-menu #transactionMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                    <button mat-menu-item (click)="addAllAgent(item)">Add to All Partners</button>
                    <button mat-menu-item (click)="removeAgent(item)">Remove Agent</button>
                    <button mat-menu-item (click)="removeAllAgent(item)">Remove from All Partners</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="loginAgent(item)">Log In as Agent</button>
                </ng-template>
            </mat-menu>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr class="listitem" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
        </table>
        <div *ngIf="loading" class="loading-indicator">
            Loading more items...
        </div>
    </div>
</div>
